import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import logo  from "../../assets/images/site-logo.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Moment from 'react-moment';
import axios from "axios";
import { Label, FormGroup, Card, CardBody,  Container, Row, Col, Alert } from "reactstrap";
class ProjectInviteUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invitedata:'',
            timeOptions: [],
            formVisible: true,
            thankYouMessage: '',
            projectdata:''
        }    
      }
      formatTime = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const timeString = `${hours}:${minutes} ${ampm}`;
        return timeString;
      }
    
      componentDidMount() {
        const { match: { params } } = this.props;
        if (params && params.projectEventInviteId) {
            const inviteId = params.projectEventInviteId;
            axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectEventInvite/id/${inviteId}`, {
              headers: {
                //'Authorization': str,
                'Content-Type': 'application/json'
              }
            })
            .then(res => {
                const invitedata = res.data;
                this.setState({ invitedata }, () => {
                  const campaignProjectId = this.state.invitedata.projectId;
                  axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectExt/uid?projectid=${campaignProjectId}`)
                  .then(response => {
                    const projectdata = response.data;
                    this.setState({ projectdata });
                    //console.log(projectdata); // Log the second API response
                  })
                  .catch(error => {
                    console.error("There was an error fetching the second data!", error);
                  });
              
                });
                this.setState({ invitedata, loading: false }, () => {
                  if (this.state.invitedata && this.state.invitedata.eventStartTime) {
                    const eventStartTime = this.state.invitedata.eventStartTime; 
                    const eventEndTime = this.state.invitedata.eventEndTime;
                    
                                const timeOptions = [];
                                let currentTime = new Date(`01/01/2000 ${eventStartTime}`);
                                const endTime = new Date(`01/01/2000 ${eventEndTime}`);
                                endTime.setHours(endTime.getHours() - 1);
                            
                                while (currentTime <= endTime) {
                                  const formattedStartTime = this.formatTime(currentTime);
                                  const nextHour = new Date(currentTime);
                                  nextHour.setHours(nextHour.getHours() + 1);
                                  const formattedEndTime = this.formatTime(nextHour);
                                  timeOptions.push(`${formattedStartTime}-${formattedEndTime}`);
                                  currentTime.setHours(currentTime.getHours() + 1);
                                }
                            
                                this.setState({
                                eventStartTime,
                                eventEndTime,
                                timeOptions,
                        });
                  }
                });
              })
            .catch(error => {
              this.setState({ error, loading: false });
            });
        
        } else {
          this.setState({ error: 'Invalid invite ID', loading: false });
        }
      }
    render() {
        const { timeOptions, invitedata } = this.state;
        const { formVisible, thankYouMessage } = this.state;
        const isEventDateTodayOrFuture = new Date(invitedata.eventDate) >= new Date().setHours(0, 0, 0, 0);
        
        return (
    <React.Fragment>
      <ToastContainer autoClose={2000} />
      <MetaTags>
            <title>Upcoming Events | Realty Space</title>
          </MetaTags>
          {this.state.projectdata.isActive && isEventDateTodayOrFuture ? ( 
        <>
          <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <img src={logo}  alt="" height="70px"/>
                    </div>
                    </Col>
                    <Col xl="6">
                        <h3 className="text-center mt-3">Register Now For Our Upcoming<br/>
                        {invitedata.projectName} {" "} at {invitedata.address}<br/>
                        for {invitedata.eventName} {" "} on <Moment format="MMMM D">{invitedata.eventDate}</Moment>
                        {" "} from <Moment format="h" parse="HH:mm">{invitedata.eventStartTime}</Moment>-<Moment format="hA" parse="HH:mm">{invitedata.eventEndTime}</Moment>

                        </h3>
                    
                    </Col>

                    <Col sm="3">
                   
                  
                   
                   
                    </Col>
                  </Row>

                </div>

            </div> 
           <div className="page-content" style={{paddingTop:40}}>
         
          <Container fluid>
          {this.state.projectdata && (
                <Row>
                  <Col xl="4"></Col>
                  <Col sm="4" className="text-center ">
                    <img className="img-fluid" src={this.state.projectdata.projectLogoLink	 }
                     alt={`${this.state.projectdata.projectName} logo`} 
                    />
                    <h2 style={{marginTop:20, marginBottom:20}}>{this.state.projectdata.projectName	}</h2>
                  </Col>
                </Row>
              )}
          <Row>
                <Col xl="4"></Col>
              <Col xl="4">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectEventInviteGuId: invitedata.projectEventInviteId,
                    id: invitedata.id,
                    projectId:invitedata.projectId,
                    projectName: invitedata.projectName,
                    name:  (this.state && this.state.name) || "",
                    phoneNo: (this.state && this.state.phoneNo) || "",
                    email:(this.state && this.state.email) || "",
                    eventSlot:'',
                    isRealator: false,
                    
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("This is Required"),
                    eventSlot: Yup.string().required("This is Required"),
                    email: Yup.string().required("This is Required"),
                    phoneNo: Yup.string().required("This is Required")
                    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                  })}

                  onSubmit={values => {
                    const updateFeature = {
                        projectEventInviteGuId: values.projectEventInviteGuId,
                        projectEventInviteId: parseInt(values.id),
                        projectId: values.projectId,
                        projectName: values.projectName,
                        name: values.name,
                        phoneNo: values.phoneNo,
                        email:values.email,
                        eventSlot:values.eventSlot,
                        isRealator: (values.isRealator=="true") ? true : false,
                    };
                   
                    toast.loading("Please wait...");
                    axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEventInviteRegistration', updateFeature, {
                      headers: {
                        'Content-Type': 'application/json',
                        //'Authorization': str
                      }
                    })
                  .then(response => {
                    toast.dismiss();
                    
                    if(response.data.success){
                      toast.success("Registration Saved");
                      this.setState({
                        formVisible: false,
                        thankYouMessage:`We would like to thank you for your inquiry. We will be in touch with you very soon!`,
                      });
                    }else{
                      response.data.validationErrors.forEach(error => {
                        toast.error(error);
                      });
                    }
                   
                  })
                  .catch(error => {
                    console.error(error);
                   
                  });
                  }
                }

                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                {formVisible ? (
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="name">
                              {this.props.t("Name*")}
                            </Label>
                           <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                   

                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="phoneNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="phoneNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.phoneNo && touched.phoneNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="phoneNo"
                            />
                             <ErrorMessage
                                  name="phoneNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventSlot">
                              {this.props.t("Slot")}
                            </Label>
                            <Field as="select" name="eventSlot"
                            className={
                            "form-control" +
                            (errors.eventSlot && touched.eventSlot
                                ? " is-invalid"
                                : "")
                            }
                            >
                            <option value="">Select</option>
                            {timeOptions.map((time, index) => (
                                <option key={index} value={time}>{time}</option>
                            ))}
                            </Field>
                             <ErrorMessage
                                  name="eventSlot"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isRealator">
                                    {this.props.t("Are You A Realtor?")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline1"
                                      name="isRealator"
                                      className="form-check-input"
                                      value="true"
                                    
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline1">
                                      Yes
                                    </label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline2"
                                      name="isRealator"
                                      className="form-check-input"
                                      value="false"
                                    
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline2">
                                      No
                                    </label>
                                  </div>
                            
                                </FormGroup>
                              </Col>
                   
            
                        <Col sm="12">
                        <button type="submit" className="btn btn-primary w-md float-end mt-27">Submit</button>  
                        </Col>
                            
              </Row>
               ) : (
                <Row>
                <Col sm="12">
                <div className="text-center">
                  <h4 className="text-danger">Thank you </h4>
               
               <h5>
                  {thankYouMessage}
               </h5>
                </div>
                </Col>
                </Row>
              )}
                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
            </Col>

        </Row>

      </Container>
          </div>
          </>
          ):(
            <>
        <div className="page-content">
           <Container fluid>
            <Row>
                <Col sm="3"></Col>
                <Col sm="6">
                    <Alert color="warning" className="text-center"><h5>Sorry no events found</h5></Alert>
                </Col>
            </Row>
           </Container>
          </div>
            </>
          )}
    </React.Fragment>
  )
}
}
ProjectInviteUser.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
}

const mapStateToProps = ({ Project }) => (
  {
   
    loading:Project.loading

  })
const mapDispatchToProps = dispatch => ({
 


});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectInviteUser));


